.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.RaCreateButton-floating {
  position: relative !important;
  inset: auto auto auto auto !important;
}

.css-14iiwxo-MuiButtonBase-root-MuiFab-root-RaCreateButton-root {
  width: 40px !important;
  height: 40px !important;
}

.css-8l66u2-MuiStack-root-RaLabeled-root {
  width: 99%;
}

p:hover {
  cursor: pointer;
  color: #00008b;
}

.RaSimpleFormIterator-line {
  justify-content: space-around !important;
  margin: 0 0 0 0 !important;
}

.RaSimpleFormIterator-buttons {
  margin-left: 3% !important;
}

.daily-programs label span {
  margin-left: 4%;
}

@media screen and (min-width: 1200px) {
  .RaSimpleFormIterator-inline {
    flex-wrap: nowrap !important;
  }
}

.css-kqreo4-MuiFormControl-root-RaArrayInput-root .RaArrayInput-label {
  left: 0px;
}
